import Banner from './components/Banner';
import Nosotros from './components/Nosotros';
import Certificacione from './components/Certificaciones'
import Soluciones from './components/Soluciones.js'
import Servicios from './components/Servicios'
import Vehiculos from './components/Vehiculos.js'
import Contacto from './components/Contacto'
import './App.css';

function App() {
  return (
    <div className="App">
      <Banner />
      <Nosotros />
      <Certificacione />
      <Soluciones />
      <Servicios />
      <Vehiculos/>
      <Contacto />
    </div>
  );
}

export default App;