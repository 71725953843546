import React,{useState} from 'react'
import '../scss/Contacto.scss'

function Contacto() {
  const [datos, setDatos] = useState({
    nombre:'',
    email:'',
    telefono:'',
    pdf:''
  })
  const handleChange = (e)=>{
    setDatos({
      ...datos,
    [e.target.name] : e.target.value 
    })
  }
  const disableButton = () =>{
    const btn = document.querySelector('.btnsend')
    const loading = document.getElementById('load')
    const enviar = document.getElementById('envio')
    btn.style.opacity = 0;
    btn.style.visibility = "hidden";
    enviar.innerHTML ="Enviando...."
    loading.style.display = "block";
   }
  
  const  enviarDatos =  (e)=>{
    e.preventDefault();
    disableButton();
  const form_data = new FormData(document.getElementById('formulario'))
    const btn = document.querySelector('.btnsend')
    const loading = document.getElementById('load')
    const enviar = document.getElementById('envio')
 fetch('/api/enviar_mail_con_cv',{
    method: 'POST',
    mode:'no-cors',
    headers:{
      "Content-Type":"application/json",
      Accept:"application/json"
    },
    body:form_data
})
.then(res => {
  if(res.ok == true ){
    setTimeout(function(){
      loading.style.display = "none";
      btn.style.opacity = 1;
      btn.style.visibility = "visible";
      enviar.innerHTML =""
      alert("Mensaje enviado, Gracias por contactarnos");
      btn.innerText = "Enviar";
      }, 4000);
  } 
   if(res.ok  == false){
    setTimeout(function(){
      loading.style.display = "none";
      btn.style.opacity = 1;
      btn.style.visibility = "visible";
      enviar.innerHTML =""
      alert("Error al enviar mensaje");
      btn.innerText = "Enviar";
      }, 4000);
  } 
})
}
function OpenForm() {
  document.getElementById('pdf').click();
}
function cambiarNombre() {
  var pdrs = document.getElementById('pdf').files[0].name;
  document.getElementById('openFile').innerHTML = pdrs;
}
  return(
    <div className="Contacto pt-5">
      <div className="container pb-5">
        <h3 className="title">Trabajá con nosotros</h3>
        <div className="form-contacto">
          <div className="row">
            <div className="col-sm-12 col-lg-4 col-md-6">
              <form className="d-flex flex-column" id="formulario" onSubmit={enviarDatos} accept-charset="UTF-8">
                <input type="text" className="my-2" placeholder="Nombre y Apellido" onChange={handleChange} name="nombre"  required></input>
                <input type="email" className="my-2" placeholder="Email"  name="email" onChange={handleChange}  required></input>
                <input type="tel" className="my-2" placeholder="Teléfono"  onChange={handleChange} name="telefono" required/>
                <div className='contentFile' style={{zIndex:'99999'}}>
                  <button  id="openFile" type='button' className='openfile'   onClick={OpenForm}>Cargá tu cv</button>
                <input name="pdf" id='pdf' type="file" onChange={cambiarNombre} accept="application/pdf" required style={{cursor:'pointer',display:'none'}}/>
                </div>
                <span ><img src="./assets/circles.svg" id="load" alt="loading" style={{width:"50px",display:"none"}}/></span>
                <button style={{zIndex:'99999'}} type="text" className="btn-form btnsend"  >Enviar</button >
                <span id="envio" style={{fontSize:"20px",fontWeight:"bold",color:"#fff"}}></span>
              </form>
            </div>
            <div className="col-sm-12 col-lg-4 col-md-6 px-xl-5 second-conlumn">
              <h2 className="mb-4">COMUNICATE CON NOSOTROS</h2>
              <div className="row">
                <span><i class="fas fa-phone-alt" style={{paddingRight:"10px", paddingBottom:"15px"}}></i>011 4489 0561 / 011 5365 7208</span>
              </div>
              <div className="row">
              <span><img src="./assets/email.svg" alt="email" style={{paddingRight:"10px", paddingBottom:"5px"}}/>Info@globaly.com.ar</span>
              </div>
              <div className="row">
                <div className="my-3">
                <span><i class="fas fa-map-marker-alt" style={{paddingRight:"10px"}}></i>Av. Presidente Perón 7685</span>
                    <span style={{display:"block", paddingLeft:"20px"}}>B1715AZA Udaondo Ituzaingó</span>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4 col-md-12">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3282.9504956311766!2d-58.67670348476968!3d-34.6306912804525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcbf1a1bdc3ca7%3A0x361fea24b832e137!2sAv.%20Pres.%20Per%C3%B3n%207685%2C%20B1715AZA%20Udaondo%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1622142016036!5m2!1ses!2sar" width="100%" height="400px" allowfullscreen="" loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
      <footer style={{marginTop:"80px"}}>
        <p>Todos los derechos reservados. Globaly</p>
      </footer>
    </div>
  );
}
export default Contacto;
