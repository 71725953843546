import React from 'react'
import '../scss/Soluciones.scss'


function Soluciones() {
  return (
    <div className="Soluciones my-5" id="soluciones">
      <div className="container">
        <div className="intro">
          <h1>Soluciones</h1>
        </div>
        <div className="soluciones-accordions">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button collapsed" id="blue" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="elipse e01">
                     <img className="clinico" src="./assets/clinico.svg" alt="clinico"/>
                     <img className="clinico2" src="./assets/clinico2.svg" alt="clinico"/>
                    </div>
                    <p className="mb-0 mx-2 title-drop cambiarColor">
                      CLÍNICO
                    </p>
                  </div>
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body e01">
                <div className="row list ">
                  <hr/>
                    <div className="col-md-6">
                      <ul>
                      <li>FARMACÉUTICOS</li>
                      <li>MEDICAMENTOS</li>
                      <li>BIOCIDAS Y FITOSANITARIOS</li>
                      <li>DISOVENTES ORGÁNICOS</li>
                      <li>CONTRASTES</li>
                      <li>SUSTANCIAS QUÍMICAS NO IDENTIFICADAS DESDE INVESTIGACIONES</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                      <li>MATERIALES RADIOLÓGICOS</li>
                      <li>SOLUCIONES ÁCIDAS</li>
                      <li>ENVASES ABSORVENTES</li>
                      <li>FILTROS</li>
                      </ul>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                    <a href="https://globaly.com.ar/CLINICO.pdf" target="_blank" className="btn-accordion">
                    VER CLASIFICACIÓN
                    </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" id="yellow" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="elipse e02">
                      <img className="indu" src="./assets/indu.svg" alt="industrial" />
                      <img className="indu2" src="./assets/indu2.svg" alt="industrial" />
                    </div>
                    <p className="mb-0 mx-2 title-drop">
                      INDUSTRIAL
                    </p>
                  </div>
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body e02">
                  <div className="row list">
                    <hr/>
                    <div className="col-md-3">
                      <ul>
                        <li>Farmacéuticos</li>
                        <li>Metales Y Plásticos</li>
                        <li>Aceites E Hidrocarburos</li>
                        <li>Maderas Biocidas Y</li>
                        <li>Fitosanitarios</li>
                        <li>Aceites Minerales</li>
                        <li>Tintas Colorantes</li>
                        <li>Pinturas</li>
                        <li>Resibas Latex</li>
                        <li>Plastificantes Adhesivos</li>
                      </ul>
                    </div>
                    <div className="col-md-3">
                      <ul>
                      <li>Tratamientos Térmicos</li>
                      <li>Materiales De Imprenta</li>
                      <li>Materiales Fotográfico</li>
                      <li>Alquitrán De Tratamientos</li>
                      <li>Pirolíticos</li>
                      <li>Metales Carbonilos</li>
                      <li>Antimonio</li>
                      <li>Arsénico</li>
                      <li>Asbestos Polvos Y Fibras</li>
                      </ul>
                    </div>
                    <div className="col-md-3">
                      <ul>
                      <li>Berilio</li>
                      <li>Cadmio</li>
                      <li>Cianuro Orgánico</li>
                      <li>Cianuro Inorgánicos</li>
                      <li>Cobre</li>
                      <li>Cromo Hexavalente</li>
                      <li>Eteres</li>
                      <li>Fenoles</li>
                      <li>Fluor</li>
                      
                      </ul>
                    </div>
                    <div className="col-md-3">
                      <ul>
                        <li>Fosfotro Orgánico</li>
                        <li>Mercurio</li>
                        <li>Plomo</li>
                        <li>Selenio</li>
                        <li>Soluciones Acidas</li>
                        <li>Soluciones Básicas</li>
                        <li>Talio</li>
                        <li>Telurio</li>
                        <li>Zinc</li>
                        
                      </ul>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <a href="https://globaly.com.ar/INDUSTRIAL.pdf" target="_blank" className="btn-accordion">
                    VER CLASIFICACIÓN
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed title-drop"     id="grey" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <div className="elipse e03">
                  <img className="admi" src="./assets/admi.svg" alt="administrativo" />
                  <img className="admi2" src="./assets/admi2.svg" alt="administrativo" />
                </div>
                <p className="mb-0 mx-2 title-drop">
                  ADMINISTRATIVO
                </p>
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body e03">
                <div className="row list">
                  <hr/>
                    <div className="col-md-4">
                      <ul>
                      <li>PAPEL</li>
                      <li>CARTÓN</li>
                      <li>PLÁSTICO</li>
                      <li>VIDRIO</li>
                      <li>METAL</li>
                      <li>LÁMPARAS Y TUBOS</li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <ul>
                      <li>TINTAS</li>
                      <li>COLORANTES</li>
                      <li>PINTURAS</li>
                      <li>TONNERS</li>
                      <li>CARTUCHOS DE IMPRESIÓN</li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <ul>
                      <li>RAEE</li>
                      <li>RESIDUOS ELÉCTRICOS Y ELECTRÓNICOS</li>
                      <li>PC, MONITORES, TECLADOS,</li>
                      <li>MOUSES, IMPRESORAS,PLAQUETAS</li>
                      </ul>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                    <a href="https://globaly.com.ar/ADMIN.pdf" target="_blank" className="btn-accordion">
                    VER CLASIFICACIÓN
                    </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button className="accordion-button collapsed title-drop" id="blue2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <div className="elipse e02">
                  <img className="aereo" src="./assets/aereo.svg" alt="aéreo" />
                  <img className="aereo2" src="./assets/aereo2.svg" alt="aéreo" />
                </div>
                <p className="mb-0 mx-2 title-drop cambiarColor">
                  MARÍTIMO Y AÉREO
                  </p>
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                <div className="accordion-body e04">
                <div className="row list">
                  <hr/>
                    <div className="col-md-4">
                      <ul>
                      <li>INORGÁNICOS</li>
                      <li>ORGÁNICOS</li>
                      <li>HIDROCARBUROS</li>
                      <li>ACEITES MINERALES</li>
                      <li>PALLETS Y MADERA DE ESTIBA</li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <ul>
                      <li>TINTAS</li>
                      <li>COLORANTES</li>
                      <li>PINTURAS</li>
                      <li>RESINAS</li>
                      <li>BATERÍAS</li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <ul>
                      <li>ENVASES</li>
                      <li>ABSORVENTES</li>
                      <li>FILTROS</li>
                      </ul>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                    <a href="https://globaly.com.ar/MARITIMO.pdf" target="_blank" className="btn-accordion">
                    VER CLASIFICACIÓN
                    </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Soluciones;