import React from 'react'
import '../scss/Nosotros.scss'
import Form from './Form';

function Nosotros() {
  return (
    <div className="Nosotros my-3" id="nosotros">
      <div className="container col-md-10">
        {
        
        window.screen.width <=992 && <Form/>
        }
        <div className="intro mb-2">
          <h1>Nosotros</h1>
          <p className="bajada">Globaly es una empresa dedicada al manejo integral de Residuos Patogénicos y Peligrosos.</p>
          <p>Sin mínimos de peso y con las habilitaciones para operar en ámbito Nacional, Provincial, Municipal, y C.A.B.A.</p>
        </div>
        <div className="bloqs row">
          <div className="col-lg-4  mb-3 mb-lg-0 content" >
            <div className="img-bloq" style={{ backgroundImage: "url(./assets/valores.png)"}} ></div>
            <div className="info box-1">
              <h4 style={{fontWeight:"bold"}}>VALORES</h4>
              <p style={{fontWeight:"bold"}}>Compromiso / Responsabilidad / Seguridad</p>
              <p className="py-1" style={{fontWeight:"bold"}}>Guiados por nuestro valores nos conduciomos en nuestro compromisos</p>
              <p className="visible">Trabajamos sobre los objetivos con responsabilidad a través de la consolidación de las relaciones personales y laborales con nuestros empleados, clientes y proveedores.
              Nuestras acciones y condiciones de gestión y trabajo contienen el máximo valor de seguridad. Todo esto se verifica en la calidad,
              integración y mejora continua de los servicios que brindamos,
              permitiéndonos posicionarnos como una de las empresas líderes en el mercado.
              </p>
              <div className="row">
                <p className="vermas">Leer más <i class="fas fa-chevron-down"></i></p>
                <p className="ocultar">Leer menos <i class="fas fa-chevron-up"></i></p>
              </div>
            </div>
            
          </div>
          <div className="col-lg-4 mb-3 mb-lg-0 content1">
            <div className="img-bloq" style={{ backgroundImage: "url(./assets/mision.png)"}}></div>
            <div className="info box-2">
              <h4 style={{fontWeight:"bold"}}>MISIÓN </h4>
              <p className="py-1" style={{fontWeight:"bold"}}>Proteger para preservar el medio ambiente.</p>
              <p style={{fontWeight:"bold"}}>Cuidar a las personas en su comunidad y ámbitos de trabajo. Trabajar en el presente por un futuro cada vez más sustentable.</p>
              <p className="visible" >Antes estos desadíos nuestro trabajo debe asegurar el adecuado manejo,tratamiento,operación y disposición final de los residuos peligrosos.
              El sometimiento a las leyes y a las autoridades de aplicación garantiza el correcto desarrollo de las operaciones.
              Nuestra misión nos compromete a mantener siempre  actualizadas las mejores tecnologías aplicables al transporte, tratamiento y operación.
              </p>
              <div className="row">
                <p className="vermas">Leer más <i className="fas fa-chevron-down"></i></p>
                <p className="ocultar">Leer menos <i className="fas fa-chevron-up"></i></p>
            </div>
            </div>
            
          </div>
          <div className="col-lg-4 mb-3 mb-lg-0  content3" >
            <div className="img-bloq" style={{ backgroundImage: "url(./assets/vision.png)"}}>
            </div>
            <div className="info box-3">
              <h4 style={{fontWeight:"bold"}}>VISIÓN</h4>
              <p className="py-21" style={{fontWeight:"bold"}}>Ser líderes y referentes en el mercado de servicios de residuos peligrosos a través del diseño y ejecución de proyectos que aporten soluciones específicas e innovadoras.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Nosotros;