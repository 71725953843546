import React,{useState} from 'react'
import '../scss/Form.scss'


function Form() {
  const [datos, setDatos] = useState({
    nombreContacto:'',
    mailContacto:'',
    mensajeContacto:''
  })
  const handleChange = (e)=>{
    setDatos({
      ...datos,
    [e.target.name] : e.target.value 
    })
  }
  const disableButton = () =>{
    const btn = document.querySelector('button')
    const enviar = document.getElementById('enviar')
    btn.style.opacity = 0;
    btn.style.visibility = "hidden";
    enviar.innerHTML ="Enviando...."
   }
  
  const  enviarDatos =  (e)=>{
    e.preventDefault();
    const btn = document.querySelector('button')
    const loading = document.getElementById('loading')
    const enviar = document.getElementById('enviar')
 fetch('/api/contacto',{
    method: 'POST',
    headers:{
      "Content-Type":"application/json",
      Accept:"application/json"
    },
    body:JSON.stringify(datos)
})
.then(res => {
  if(res.ok == false){
    loading.style.display = "block";
    setTimeout(function(){
      loading.style.display = "none";
      btn.style.opacity = 1;
      btn.style.visibility = "visible";
      enviar.innerHTML =""
      alert("Error: Ingrese bien los datos");
      btn.innerText = "Enviar";
      }, 4000);
  }
  if(res.ok  == true){
    loading.style.display = "block";
    setTimeout(function(){
      loading.style.display = "none";
      btn.style.opacity = 1;
      btn.style.visibility = "visible";
      enviar.innerHTML =""
      alert("Mensaje enviado, Gracias por contactarnos");
      btn.innerText = "Enviar";
      }, 4000);
  }
})
}

  return (
    <div className="Form">
      <h3>COMPLETÁ TUS DATOS PARA CONTACTARNOS:</h3>
      <p className="contacto">CONTACTO</p>
      <form className="d-flex flex-column align-items-end" onSubmit={enviarDatos}>
        <input type="text" placeholder="Nombre y Apellido" name="nombreContacto" onChange={handleChange} required></input>
        <input type="email" placeholder="Email"  name="mailContacto" onChange={handleChange} required></input>
        <input type="text" placeholder="Mensaje" className="mensaje" name="mensajeContacto" onChange={handleChange} required></input>
        <div className="d-flex flex-column align-items-end">
          <span ><img src="./assets/circles.svg" id="loading" alt="loading" style={{width:"50px",display:"none"}}/></span>
     
        <button type="submit" id="btnSend"  className="btn-form" onClick={disableButton} >Enviar</button >
        <span id="enviar" style={{fontSize:"20px",fontWeight:"bold"}}></span>
      </div>
      </form>
      <div className="mas-info">
        <p>Ó COMUNICATE CON NOSOTROS</p>
        <div className="row">
          <span><i class="fas fa-phone-alt" style={{paddingRight:"10px", paddingBottom:"15px", color:"#EC6D2D",fontWeight:"800"}}></i>011 4489 0561 / 011 5365 7208</span>
        </div>
        <div className="row">
        <span><img src="./assets/email2.svg" alt="email" style={{paddingRight:"10px", paddingBottom:"3px"}}/>Info@globaly.com.ar</span>
        </div>
      </div>
    </div>
  );
}

export default Form;