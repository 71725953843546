import React from 'react'
import Carousel from 'react-elastic-carousel'
import '../scss/Vehiculos.scss'


function Vehiculos() {
  return (
    <div className="Vehiculos py-5" style={{ backgroundImage: "url(./assets/bg-vehiculos.png)"}}>

      <div className="container">
        <div className="intro">
          <h1>NUESTROS VEHÍCULOS</h1>
        </div>
        {window.screen.width >=700 &&
        <Carousel itemsToShow={1}  pagination={true} className="carousel">
        <div className="item " >
            <img className="autos" src="./assets/vehiculos-01.png" alt="vehiculos" />
        </div>
        <div className="item car">
            <img className="autos" src="./assets/vehiculos-02.png" alt="vehiculos" />
        </div>
        <div className="item car2" >
            <img className="autos" src="./assets/vehiculo5.png"  alt="vehiculos" style={{width:"70%",marginTop:"2rem"}}/>
        </div>
        </Carousel> }

        {window.screen.width <= 699 && <Carousel itemsToShow={1} pagination={true} className="carousel">
        <div className="item">
            <img className="autos" src="./assets/vehiculo1.png" alt="vehiculos" />
        </div>
        <div className="item">
            <img className="autos" src="./assets/vehiculo2.png" alt="vehiculos" style={{marginTop:"10px"}}/>
        </div>
        <div className="item">
            <img className="autos" src="./assets/vehiculo3.png" alt="vehiculos" style={{marginTop:"10px"}} />
        </div>
        <div className="item">
            <img className="autos" src="./assets/vehiculo4.png" alt="vehiculos" style={{marginTop:"10px"}}/>
        </div>
        <div className="item">
            <img className="autos" src="./assets/vehiculo5.png" alt="vehiculos" style={{marginTop:"10px"}} />
        </div>
        </Carousel>}
      </div>
    </div>
  );
}
export default Vehiculos;