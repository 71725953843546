import React, {useState} from 'react'
import '../scss/Header.scss'


function Header() {
  const [click, setClick] = useState(false);
  const handleClick = ()=> setClick(!click);
  const closeMobileMenu = () =>setClick(false);
  return (
    <header className="Header">
      <div className="gestion">
          <img src="./assets/logo.svg" alt="logo" className="logo" />
          <h4 className="titulo">GESTIÓN INTEGRAL DE RESIDUOS</h4>
      </div>
      <nav className="nav">
      <div className="menu-icon" onClick={handleClick}>
        <i className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
      </div>
      <div className= 
     {click ? 'nav-menu active': 'nav-menu '}>
        <a href="#nosotros"  onClick={closeMobileMenu}>Nosotros <i className="fas fa-chevron-right icon1"></i></a>
        <a href="#soluciones" onClick={closeMobileMenu}>Soluciones <i className="fas fa-chevron-right icon2"></i></a>
        <a href="#servicio" onClick={closeMobileMenu}>Servicio <i className="fas fa-chevron-right icon3"></i></a>
        <a href="https://globaly.com.ar/tienda/" target="_blank" onClick={closeMobileMenu}>Tienda <i className="fas fa-chevron-right icon3"></i></a>
       <a href="https://api.whatsapp.com/send?phone=5491139570438&text=Hola!%20Quisiera%20hacer%20una%20consulta" target="_blank" className="wpp"  onClick={closeMobileMenu}><img src="./assets/ws.svg" className="ws" style={{paddingBottom:"3px",paddingRight:"3px"}} /> Envianos un Whatsapp</a>
        <a href="http://clientes.globaly.com.ar/" target="_blank" className="btn-header" onClick={closeMobileMenu}>Área Clientes</a>
        <a href="https://globaly.com.ar/cotizar/cotizar-servicio.html" target="_blank" className="btn-header" onClick={closeMobileMenu}>Cotizá Online</a> 
      </div>
      </nav>
    </header>
  )
}

export default Header;