import React from 'react'
import '../scss/Certificaciones.scss'


function Certificacione() {
  return (
    <div className="Certificaciones py-5" style={{ background: '#F6F6F6'}}>
      <div className="container">
        <h1 className="mb-5">CERTIFICACIONES</h1>
        <div className="row justify-content-center">
          <div className="col-md-3 py-3 py-md-0 px-5">
            <img className="w-100" src="./assets/ministerio.jpg" alt=""/>
          </div>
          <div className="col-md-3 py-3 py-md-0 px-5">
            <img className="w-100" src="./assets/provincial.jpg" alt=""/>
          </div>
          <div className="col-md-3 py-3 py-md-0 px-5">
            <img className="w-100" src="./assets/proteccion.jpg" alt=""/>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Certificacione;