import React from "react";
import Carousel from "react-elastic-carousel";
import "../scss/Servicios.scss";

function Servicios() {
  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 850, itemsToShow: 2, itemsToScroll: 2 },
    { width: 950, itemsToShow: 2, itemsToScroll: 2 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1450, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1750, itemsToShow: 3, itemsToScroll: 3 },
  ];

  return (
    <div className="Servicios py-5" id="servicio">
      <div className="container">
        <h1>SERVICIOS</h1>
        <span className="circulo"></span>
        <span className="circulo2"></span>
        {/* <p className="desc my-3">It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p> */}
        <Carousel
          itemsToShow={3}
          breakPoints={breakPoints}
          pagination={true}
          className="carousel"
        >
          <div className="item">
            <div className="elipce">
              <img src="./assets/retiro.svg" alt="retiro" />
            </div>
            <div className="mb-3 texto">SISTEMAS DE RECOLECCIÓN</div>
            <div>
              <p>
                Proveemos e instalamos recipientes especificamente diseñados
                para cada ámbito y tipo de residuo de manera que se puedan
                verter y colectar rápida, clasificada y eficazmente.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="elipce verde">
              <img src="./assets/reciclado.svg" alt="reciclado" />
            </div>
            <div className="mb-3 texto ">RECICLADO</div>
            <div>
              <p className="textCorto">
                Proceso de conversión en materia o nuevos productos.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="elipce verde">
              <img src="./assets/transporte.svg" alt="transporte" />
            </div>
            <div className="mb-3 texto ">TRANSPORTE</div>
            <div>
              <p className="textCorto">
                Contamos con vehículos propios y personal especializado.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="elipce verde">
              <img src="./assets/tratamiento.svg" alt="tratamiento" />
            </div>
            <div className="mb-3 texto ">TRATAMIENTO</div>
            <div>
              <p className="textCorto">
                Aplicando métodos físicos, químicos o biológicos o combinación
                de ellos.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="elipce verde">
              <img src="./assets/disposicion.svg" alt="disposicion" />
            </div>
            <div className="mb-3 texto ">DISPOSICIÓN FINAL</div>
            <div>
              <p className="textCorto">
                Rellenos sanitarios, depósitos de seguridad, reservorios.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="elipce azul">
              <img src="./assets/recoleccion.svg" alt="recoleccion" />
            </div>
            <div className="mb-3 texto ">RETIRO</div>
            <div>
              <p className="textCorto">
                Periodicidad de acuerdo a la necesidad del cliente, volumen y/o
                tipo de residuo.
              </p>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}
export default Servicios;
