import React from "react";
import Header from "./Header";
import Form from "./Form";
import "../scss/Banner.scss";
import Carousel from "react-bootstrap/Carousel";

function Banner() {
  return (<>
    <div className="col-md-4 mt-1 h-100 formCarousel" >
    {
      window.screen.width >= 993 && <Form />

    }
  </div>
    <Carousel className="carouselHeader" fade controls={false}>
      {/*  <div className="col-md-4 mt-1 h-100">
         {window.screen.width >= 993 && <Form />}
        </div> */}
      <Carousel.Item interval={4000}>
        <div className="Banner" style={{position:"relative"}}>
          <div
            className="bg-banner h-100"
            style={{
              backgroundImage: "url(./assets/banner.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <div className="container h-100">
              <Header />
              <div className="row h-100 d-flex align-items-start">
                <div className="col-md-8 d-flex justify-content-start">
                  <p className="txt">nos encanta cuidarte. </p>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={4000}>
        <div className="Banner2">
          <div
            className="bg-banner h-100"
            style={{
              backgroundImage: "url(./assets/banner2.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <div className="container h-100">
              <Header />
              <div className="row h-100 d-flex align-items-start">
                <div className="col-md-8 d-flex justify-content-start">
                  <p className="txt">
                    estamos centrados en mejorar y <br />
                    crear nuevas soluciones sostenibles <br /> para cada ambito
                    de aplicacion
                  </p>
                </div>
                {/* <div className="col-md-4 mt-1 h-100">
                  {window.screen.width >= 993 && <Form />}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={4000}>
        <div className="Banner3">
          <div
            className="bg-banner h-100"
            style={{
              backgroundImage: "url(./assets/banner3.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <div className="container h-100">
              <Header />
              <div className="row h-100 d-flex align-items-start">
                <div className="col-md-8 d-flex justify-content-start">
                  <p className="txt">
                    te ayudamos a gestionar los <br />
                    residuos de forma más valiosa y<br />
                    responsable con el medio ambiente.
                  </p>
                </div>
               {/*  <div className="col-md-4 mt-1 h-100">
                  {window.screen.width >= 993 && <Form />}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={4000}>
        <div className="Banner4">
          <div
            className="bg-banner h-100"
            style={{
              backgroundImage: "url(./assets/banner4.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <div className="container h-100">
              <Header />
              <div className="row h-100 d-flex align-items-start">
                <div className="col-md-8 d-flex justify-content-start">
                  <p className="txt">
                    protejamos nuestro planeta de <br />
                    manera proactiva. minimicemos el
                    <br />
                    impacto ambiental para prosperar.
                  </p>
                </div>
                {/* <div className="col-md-4 mt-1 h-100">
                  {window.screen.width >= 993 && <Form />}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
     
    </Carousel></>
  );
}
export default Banner;
